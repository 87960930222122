export const defaults = {
    maps: {
        map1: {
            center: {
                lat: 41.8781136,
                lng: -87.6606758,
            },
        },
        map2: {
            center: {
                lat: 40.7127753,
                lng: -73.9750952,
            },
        },
    },
    maxZoom: 20,
    minZoom: 4,
    overlay: false,
    zoom: 14,
};
